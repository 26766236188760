/* globals gtag */
import { observable, action, decorate } from 'mobx';

class ContextModel {
    currentPage = 'paintings';
    menuState = false;
    imageView = false;
    selectedImage = null;
    screenType = 'desktop';
    paintings = {};
    graphics = {};

    toggleMenu = () => {
        this.menuState = !this.menuState;
        if (this.menuState) {
            gtag('event', 'menu open', {
                event_category: 'menu'
            });
        }
    }

    setPage = (pageName) => {
        this.menuState = false;
        this.currentPage = pageName;
    }

    setPaintings = (paintings) => {
        this.paintings = {
            leftMost: 0,
            list: paintings
        }
        this.currentPage = 'paintings';
    }

    setGraphics = (graphics) => {
        this.graphics = {
            leftMost: 0,
            list: graphics
        }
        this.currentPage = 'graphics';
    }

    showImageView = (image) => {
        this.imageView = true;
        this.selectedImage = image;
        gtag('event', 'image preview', {
            event_category: 'image',
            event_label: this.selectedImage.title
        });
    }

    hideImageView = () => {
        this.imageView = false;
        this.selectedImage = null;
    }

    scrollLeft = () => {
        const list = this[this.currentPage];
        if (list.leftMost > 0) {
            const nextImage = this.screenType === 'mobile' ? list.leftMost - 1 : list.leftMost - 3;
            list.leftMost = nextImage;
            gtag('config', 'UA-162933668-1', { page_path: `/${this.currentPage}/${nextImage}` });
        }
    }

    scrollRight = () => {
        const list = this[this.currentPage];
        if (list.leftMost < list.list.length - 1) {
            const nextImage = this.screenType === 'mobile' ? list.leftMost + 1 : list.leftMost + 3;
            list.leftMost = nextImage;
            gtag('config', 'UA-162933668-1', { page_path: `/${this.currentPage}/${nextImage}` });
        }
    }

    changeScreenType = (screenType) => {
        this.screenType = screenType;
    }
};

decorate(ContextModel, {
    currentPage: observable,
    menuState: observable,
    imageView: observable,
    selectedImage: observable,
    paintings: observable,
    graphics: observable,
    screenType: observable,
    toggleMenu: action,
    showImageView: action,
    hideImageView: action,
    setPage: action,
    setPaintings: action,
    setGraphics: action,
    scrollLeft: action,
    scrollRight: action,
    changeScreenType: action
});

export const ContextStore = new ContextModel();